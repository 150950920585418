import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form, DatePicker, Input, InputNumber, Button, Upload, Select } from 'antd';
import { CLAIM_TYPE, BANK } from '../utils/constant';
import { keys } from 'lodash/fp';
import * as dayjs from 'dayjs';
import { Modal } from 'antd';

import styles from './ClaimNew.module.scss';
import _ from 'lodash';

const { Option } = Select;

const ClaimNew = ({ submitClaim, form, isShowModal, duplicateMessage, setIsShowModal, submitForm }) => {
  const [claimType, setClaimType] = useState(null);

  const onSubmit = (data) => {
    const formattedData = _.mapValues(data, (val) => typeof val === 'string' ? val.trim() : val);
    submitClaim({...formattedData, skip_duplicate: form.getFieldValue('skip_duplicate')});
  };

  return (
    <>
     <Modal title="มีข้อมูลเคลมนี้แล้ว" visible={isShowModal} onOk={() => submitForm()} onCancel={() => setIsShowModal(false)} >
      {duplicateMessage}
    </Modal>
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600, margin: 'auto' }}
      onFinish={onSubmit}
    >
        <Form.Item
          label="เลขกรมธรรม์" // policy number
          name="policy_number"
          rules={[{ required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ชื่อผู้รับความคุ้มครอง" // insured name
          name="insured_name"
          rules={[{ required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="เลขบัตรปชช." // insured id
          name="insured_id"
          rules={[{ required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="โทรศัพท์" // phone
          name="phone"
          rules={[{ whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="อีเมล" // email
          name="email"
          rules={[{ whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ยอดเคลม" // claimed amount
          name="claimed_amount"
          rules={[{ required: true }]}
        >
          <InputNumber
            className={styles.datePicker}
            prefix="฿"
            controls={false}
            min={0}
            precision={2}
          />
        </Form.Item>
        <Form.Item
          label="ประเภทเคลม" // claim type
          name="type"
          rules={[{ required: true, message: 'โปรดระบุประเภทเคลม' }]}
        >
          <Select
            placeholder="เลือกประเภทเคลม"
            allowClear
            onChange={(val) => setClaimType(val)}
          >
            {
              keys(CLAIM_TYPE).map(
                (code) => (
                  <Option key={code} value={CLAIM_TYPE[code]}>{code}</Option>
                )
              )
            }
          </Select>
        </Form.Item>
        <Form.Item
          label="วันเริ่มการรักษา" // treatment start
          name="treatment_start"
          rules={[{ required: true }]}
        >
          <DatePicker
            format={'DD/MM/YYYY'}
            className={styles.datePicker}
            disabledDate={(current) => {
              return current && current > dayjs().endOf('day');
            }}
          />
        </Form.Item>
        {
          (claimType === CLAIM_TYPE.IPD || claimType === CLAIM_TYPE.HB_INCENTIVE || claimType === CLAIM_TYPE.HB) &&
          <Form.Item
            label="วันสิ้นสุดการรักษา" // treatment end
            name="treatment_end"
            rules={[{ required: true }]}
          >
            <DatePicker
              format={'DD/MM/YYYY'}
              className={styles.datePicker}
              disabledDate={(current) => {
                return current && current > dayjs().endOf('day');
              }}
            />
          </Form.Item>
        }
        {
          /*
        <Form.Item
          label="รหัสธนาคาร" // bank name
          name="bank_name"
        >
          <Input disabled/>
        </Form.Item>
        <Form.Item
          label="ชื่อธนาคาร" // bank name
          name="bank_name"
          rules={[{ required: false, message: 'ชื่อธนาคาร ห้ามเป็นค่าว่าง' }]}
        >
          <Select
            placeholder="เลือกธนาคาร"
            allowClear
          >
            {
              keys(BANK).map(
                (code) => (
                  <Option key={code} value={code}>{BANK[code].name.localize.th}</Option>
                )
              )
            }
          </Select>
        </Form.Item>
        <Form.Item
          label="ชื่อบัญชี"
          name="bank_account_name"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="เลขบัญชี"
          name="bank_account_number"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        */
          }
        <Form.Item
          label="ใบเสร็จ"
          name="receipt_file"
          rules={[{ required: true }]}
        >
          <Upload maxCount={1} listType="picture" customRequest={(cb) => cb.onSuccess()}>
            <Button icon={<UploadOutlined/>}>เลือกไฟล์</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="ใบรับรองแพทย์"
          name="med_cert_file"
          rules={[{ required: true }]}
        >
          <Upload maxCount={1} listType="picture" customRequest={(cb) => cb.onSuccess()}>
            <Button icon={<UploadOutlined />}>เลือกไฟล์</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="หน้าสมุดธนาคาร"
          name="bank_account_file"
        >
          <Upload maxCount={1} listType="picture" customRequest={(cb) => cb.onSuccess()}>
            <Button icon={<UploadOutlined />}>เลือกไฟล์</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="อื่นๆ 1"
          name="other_file_1"
        >
          <Upload maxCount={1} listType="picture" customRequest={(cb) => cb.onSuccess()}>
            <Button icon={<UploadOutlined />}>เลือกไฟล์</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="อื่นๆ 2"
          name="other_file_2"
        >
          <Upload maxCount={1} listType="picture" customRequest={(cb) => cb.onSuccess()}>
            <Button icon={<UploadOutlined />}>เลือกไฟล์</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="อื่นๆ 3"
          name="other_file_3"
        >
          <Upload maxCount={1} listType="picture" customRequest={(cb) => cb.onSuccess()}>
            <Button icon={<UploadOutlined />}>เลือกไฟล์</Button>
          </Upload>
        </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
      <Button type="primary" htmlType="submit">
        ยืนยัน
      </Button>
      </Form.Item>
    </Form>
    </>
  );
};

ClaimNew.propTypes = {

};

ClaimNew.defaultProps = {
};

export default ClaimNew;